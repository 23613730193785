import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import "./ContentWrapper.css";
import Footer from './Footer/Footer';
import Header from './Header/Header';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function ContentWrapper(props) {
    const innerContent = props.children;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

    const containerWidth = isMobile ? "100%" : "75%";

    return (
        <Box
            sx={{
                mx: 'auto',
                pt: "3rem",
                width: containerWidth,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                ...(isMobile && { overflowX: 'hidden' }),
            }}>
            <React.Fragment>
                <CssBaseline />
                <Header />
                <Container maxWidth={false}
                    sx={{
                        mx: 'auto',
                        pt: "0rem",
                        pl: "0rem",
                        pr: "0rem",
                        mb: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 0
                    }}>
                    <Box sx={{ mt: 4 }}>
                        {innerContent}
                    </Box>
                </Container>
                <Footer />
            </React.Fragment>
        </Box>
    );
}

export default ContentWrapper;
