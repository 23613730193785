import * as React from 'react';
import Box from '@mui/material/Box';
import CheckNow from './CheckNow/CheckNow';
import UseCases from "./UseCases/UseCases"
import Promises from './Promises/Promises';
import { Element } from 'react-scroll';
import Contact from './Contact/contact';

function Homepage(props) {
    return (
        <Box name="start">
            <Element name="checknow">
                <CheckNow />
            </Element>
            <Element name="services">
                <UseCases />
            </Element>
            <Element name="promisses">
                <Promises />
            </Element>
            <Element name="contact">
                <Contact />
            </Element>
        </Box>
    );
}

export default Homepage;