import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function UseCases(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const elements = [
        { id: 1, name: 'Gewerbereinigung' },
        { id: 2, name: 'Büroreinigung' },
        { id: 3, name: 'Baureinigung' },
        { id: 4, name: 'Treppenhausreinigung' },
        { id: 5, name: 'Haus- und Wohnungsreinigung' },
        { id: 6, name: 'Fensterreinigung' },
        { id: 7, name: 'Kellerreinigung' },
        { id: 8, name: 'Carportsreinigung' },
        { id: 9, name: 'Gartenhäuserreinigung' },
        { id: 10, name: 'Zaunelementen- und Torenreinigung' },
        { id: 11, name: 'Grundreinigung' },
        { id: 12, name: 'Wir helfen mit Umzug!' },
    ];

    return (
        <Box sx={{ mt: isMobile? 3:8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant={isMobile? "h6": "h4"} align="center" sx={{ fontWeight: 'bold', pb: isMobile? 3:7 }}>Unsere Leistungen im Überblick:</Typography>
            <Grid container spacing={2} sx={{ width: isMobile? '90%':'80%' }}>
                {elements.map(element => (
                    <Grid item xs={isMobile? 12:6} key={element.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CheckCircleIcon color='primary'/>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant={isMobile? 'body1': 'h5'}>{element.name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default UseCases;