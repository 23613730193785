import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ContactButton from '../../ContactButton/ContactButton';
import useMediaQuery from '@mui/material/useMediaQuery';


function Contact(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{ mt: 7, }}>
            <Box elevation={0} variant="outlined" sx={{
                backgroundColor: "#7ec3fc",
                padding: 4,
                color: "F0F8F8",
                borderRadius: '16px',
            }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" textAlign="center">
                    <Grid item xs={12} alignItems="center">
                        <Typography variant={isMobile? 'body1':'h6'}>
                            Sollten Sie diesbezüglich Fragen haben, sprechen Sie uns gerne an.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <Typography variant={isMobile? 'body1':'h6'}>
                            Wir erstellen Ihnen vorab gerne ein Angebot mit feiern Preisen.
                            Gerne anrufen oder per WhatsApp schreiben.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} textAlign="center" sx={{pt:3}}>
                    <ContactButton />
                </Grid>
            </Box>
        </Box>
    );
}

export default Contact;