import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function CheckNow(props) {
    const textColor = "#003e95";
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Box bgcolor={"#7ec3fc"} sx={{
            ml: -8,
            mr: -8,
            height: "100%",
            pt: 20,
            mt: -20,
            pb: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Box sx={{ width: "60%", alignItems: 'center' }}>
                <Box sx={{ textAlign: 'left', mb: 3 }}>
                <Box sx={{ pb: 2 }}>
                    <div>
                        <Typography variant={isMobile? "h6":"h4"} display="inline" style={{ textTransform: 'uppercase' }} sx={{ color: textColor, fontWeight: 'bold' }}>Professionelle</Typography>
                        <Typography variant={isMobile? "h6":"h4"} display="inline" style={{ textTransform: 'uppercase' }} sx={{ fontWeight: 'bold' }}> Reinigungsdienstleistungen</Typography>
                    </div>
                    <div>
                        <Typography variant={isMobile? "h6":"h4"} display="inline" style={{ textTransform: 'uppercase' }} align="left" sx={{ fontWeight: 'bold' }}> für </Typography>
                        <Typography variant={isMobile? "h6":"h4"} display="inline" style={{ textTransform: 'uppercase' }} sx={{ color: textColor, fontWeight: 'bold' }}>Gewerberäume und Privathaushalte.</Typography>
                    </div>
                    </Box>
                    <Typography sx={{ pb: 1 }} variant={isMobile? "body1":"h5"} align="left">Zuverlässig und mit gutem Gewissen</Typography>
                    <Typography sx={{ pb: 1 }} variant={isMobile? "body1":"h5"} align="left">Schnell und unkompliziert</Typography></Box>
            </Box>
        </Box>
    );
}

export default CheckNow;