import './App.css';
import { createBrowserHistory } from "history";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContentWrapper from './components/ContentWrapper/ContentWrapper';
import Homepage from './components/Homepage/Homepage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import colorService from './services/ColorService';
import Impressum from './components/Impressum/Impressum';

export const opts = {
  audience: process.env.REACT_APP_API_URL,
  // scope: 'read:everything',
};


const THEME = createTheme({
  typography: {
    "fontFamily": `"Outfit", Helvetica`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  button: {
    "fontFamily": `"Outfit", Helvetica`,
  },
  palette: {
    primary: {
      main: colorService.getColorFromPalette('main'),
    },
    customColor: {
      main: '#ff00ff',
    },
    secondary: {
      main: colorService.getColorFromPalette('dark'),
    },
    base: {
      main: colorService.getColorFromPalette('dark'),
    },
  },
  snackbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    background: "rgb(232, 241, 250)"
  }
});

export const history = createBrowserHistory({ basename: '/' });


function App() {
  return (
    <ThemeProvider theme={THEME}>
      <Router basename="/" history={history}>
        <ContentWrapper>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/impressum" element={<Impressum />} />
          </Routes>
        </ContentWrapper>
      </Router>
    </ThemeProvider >
  );
}

export default App;
