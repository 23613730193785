import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { scroller } from 'react-scroll';
import { Typography } from '@mui/material';
import ContactButton from '../../ContactButton/ContactButton';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



const ScaledImage = ({ src, height }, props) => {
    return (
        <div style={{ height, width: 'auto' }}>
            <img
                src={src}
                alt={props.alt}
                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            />
        </div>
    );
};


function Header(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const logo = "/logo.png";


    const scrollToSection = (sectionId) => {
        if (location.pathname !== "/") {
            navigate(`/`);
        }
        scroller.scrollTo(sectionId, {
            duration: 800,
            smooth: 'easeInOutQuart',
        });
    };

    const navItems = {
        "Home": 'start',
        'Unsere Leistungen': "services"
    };


    return (
        <AppBar position="sticky" sx={{ borderRadius: '16px', zIndex: 1, background: 'white' }} >
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    component={Link} to={`/`}
                >
                    <ScaledImage src={logo} height={38} alt="SchmutziPutzi" />
                    <Typography variant="h4" sx={{
                        paddingLeft: 1, fontFamily: 'Teko, sans-serif',

                        backgroundcolor: "primary",
                        backgroundImage: `linear-gradient(45deg, #079dfe, #4719ff)`,
                        backgroundSize: "100%",
                        backgroundRepeat: "repeat",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent"
                    }}
                    >Schmutzi Putzi</Typography>
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {
                        !isMobile ?
                            <Box>
                                {Object.entries(navItems).map(([key, value]) => (
                                    <Button onClick={() => scrollToSection(value)} color='primary' key={key} sx={{ color: 'primary', ml: 3, textTransform: 'none' }}>
                                        {key}
                                    </Button>
                                ))}
                            </Box> : null
                    }
                </Box>
                <ContactButton sx={{ ml: 2 }} />
            </Toolbar>
        </AppBar>
    );
};

export default Header;