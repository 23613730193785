import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function ContactDialog(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>Kontakt aufnehmen</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography variant='h6'>
                    Telefonisch oder via WhatsApp:
                </Typography>
                <Typography variant='h6' align='center' sx={{ p: 1 }}>
                    <Link href="tel:+49 1575 1461642" variant='h6' align='center'>
                        +49 1575 1461642
                    </Link>
                </Typography>
                <Typography variant='h6'>
                    Per E-Mail:
                </Typography>
                <Typography variant='h6' align='center' sx={{ p: 1 }}>
                    <Link href="mailto:schmutzi.putzii@gmail.com" variant='h6' align='center'>
                        schmutzi.putzii@gmail.com
                    </Link>
                </Typography>
                {
                    !isMobile? 
                    <Box>
                        <Typography variant='h6'>
                        Oder noch einfacher: Scanne den QR-Code unten!
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <img src="/QR-Code.png" alt="QR Code" width="35%" />
                    </div>
                    </Box>
                : null

                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="outlined" onClick={handleClose}>
                    Schließen
                </Button>
            </DialogActions>

        </BootstrapDialog>
    );
}

export default ContactDialog;