import * as React from 'react';
import Button from '@mui/material/Button';
import ContactDialog from './ContactDialog';

function ContactButton(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = (value) => {
      setOpen(false);
    };
  
    return (
      <div>
        <Button color='primary' variant="contained" onClick={handleClickOpen} {...props}>
          Kontakt
        </Button>
        <ContactDialog
          open={open}
          onClose={handleClose}
        />
      </div>
    );
  }

  export default ContactButton;