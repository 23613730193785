// Define your color palettes here
const colorPalettes = {
    aareal: {
        main: '#003461',
        light: '##e6f4f1',
        dark: '##eceeff',
        base: '#7ec3fc',
    },
    generic: {
        main: '#4CAF50',
        light: '#ff5983',
        dark: '#676c6f',
    },
    infab: {
        main: '#bd7775',
        light: '#d4b9b8',
        dark: '#784d4b',
        base: '#e30000',
    },
    // Add more color palettes if needed
};

// Function to get a color from the specified palette
export const getColorFromPalette = (shade = 'main') => {
    const paletteName = "aareal";//process.env.COLOR_THEME;
    const palette = colorPalettes[paletteName];
    if (!palette) {
        console.warn(`Color palette '${paletteName}' not found.`);
        return '';
    }

    return palette[shade] || '';
};

// Export the color service
const colorService = {
    getColorFromPalette,
};

export default colorService;