import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";


function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: "#003461",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="#eceeff" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="white">
              Professionelle Reinigungsdienstleistungen für Gewerberäume und Privathaushalte.
            </Typography>
            <Typography variant="body2" color="white">
              Zuverlässig und mit gutem Gewissen.
            </Typography>
            <Typography variant="body2" color="white">
              Schnell und unkompliziert.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="#eceeff" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="white">
              48565 Steinfurt
            </Typography>
            <Typography variant="body2" color="white">
              Nordrhein-Westfallen, Deutschland
            </Typography>
            <Typography variant="body2" color="white">
              T +49 1575 1461642
            </Typography>
            <Typography variant="body2" color="white">
              E schmutzi.putzii@gmail.com
            </Typography>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="white" align="center">
            <Link color="inherit" href="/impressum">
              Impressum
            </Link>
            {"  |  Copyright © "}
            <Link color="inherit" href="http://schmutzi-putzi.de/">
              Schmutzi Putzi
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;