import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';

function UseCaseElement(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid item sx={props.item.sx} xs={4} key={`ServicePresentation_${0}`}>
            <Box
                elevation={0}
                sx={{
                    ...props.theme.typography.body2,
                    padding: 1,
                    color: "F0F8F8",
                    textAlign: 'left',
                    pr: 3, pl: 5,
                }}
            >
                <Grid item xs={isMobile ? 12 : 10} key={props.item.name} sx={{ pb: 1 }}>
                    <Stack alignItems="center" direction="row" gap={2}>
                        <EmojiEmotionsIcon color='primary' fontSize={isMobile ? "medium" : "large"} />
                        <Typography variant={isMobile ? 'body1' : 'h6'}>
                            {props.item.name}
                        </Typography>
                    </Stack>
                </Grid>
            </Box>
        </Grid>
    );
}

function Promises(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const items = [
        {
            name: "Wir verwenden nur hochwertige Reinigungsmittel.",
            sx: {
                top: 5,
                left: 5,
            }
        },
        {
            name: "Wir legen Wert auf optimale Ergebnisse und Kundenzufriedenheit.",
            sx: {
                top: 5,
                right: 5,
            }
        },
        {
            name: "Wir bieten flexible Terminvereinbarungen, die sich an Ihren Zeitplan anpassen.",
            sx: {
                bottom: 5,
                left: 5,
            }
        },
        {
            name: "Unser Ziel ist es, Ihre Erwartungen zu übertreffen.",
            sx: {
                bottom: 5,
                right: 5,
            }
        },
    ];

    return (
        <Box sx={{ pt: 7 }}>
            <Divider sx={{ mb: 3 }} />
            {!isMobile ?
                <Box sx={{ mt: 8 }}>
                    <Box sx={{
                        backgroundImage: `url("elipse-1.png")`,
                        backgroundPosition: `center center`,
                        backgroundRepeat: `no-repeat`,
                        height: "50em",
                        p: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                    }}>
                        <Grid container spacing={3} sx={{ top: 0 }}>
                            <UseCaseElement item={items[0]} theme={theme} />
                            <Box sx={{ flexGrow: 1 }} />
                            <UseCaseElement item={items[1]} theme={theme} />
                        </Grid>
                        <Grid container spacing={3} sx={{ position: 'absolute', bottom: '0', }}>
                            <UseCaseElement item={items[2]} theme={theme} />
                            <Box sx={{ flexGrow: 1 }} />
                            <UseCaseElement item={items[3]} theme={theme} />
                        </Grid>

                    </Box>
                </Box>
                : <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center', // Center vertically
                            pb: 3
                        }}
                    >
                        <Box
                            component="img"
                            src={`/elipse-1.png`}
                            alt="Description"
                            sx={{
                                width: '100%', // Adjust the width as needed
                                height: 'auto', // Maintain aspect ratio
                                maxWidth: '20rem', // Set a maximum width
                            }}
                        />
                    </Box>
                    {items.map((element) => (
                        <Grid item xs={isMobile ? 12 : 10} key={element.id} sx={{ pb: 1 }}>
                            <Grid container alignItems="center">
                                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <EmojiEmotionsIcon color='primary' fontSize={isMobile ? "medium" : "large"} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant={isMobile ? 'body1' : 'h5'}>{element.name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            }
            <Divider sx={{ mt: 3 }} />
        </Box>
    );
}

export default Promises;