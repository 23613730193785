import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Impressum(props) {
    return (
        <Box sx={{ marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Impressum
            </Typography>
            <Typography variant="body1" gutterBottom>
                <strong>Firma:</strong> Beispiel GmbH<br />
                <strong>Vertreten durch:</strong> Max Mustermann<br />
                <strong>Adresse:</strong> Beispielstraße 123, 12345 Beispielstadt<br />
                <strong>Telefon:</strong> 01234-567890<br />
                <strong>E-Mail:</strong> info@example.com<br />
                <strong>Registergericht:</strong> Beispielgericht<br />
                <strong>Registernummer:</strong> HRB 12345<br />
                <strong>Umsatzsteuer-ID:</strong> DE123456789<br />
                <br />
                <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br />
                Max Mustermann<br />
                Beispielstraße 123, 12345 Beispielstadt<br />
                <br />
                <strong>Haftungsausschluss:</strong><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
        </Box>
    );
}

export default Impressum;